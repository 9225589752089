import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  MAINNET = 321,
  TESTNET = 322
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const FACTORY_ADDRESS = {
  [ChainId.TESTNET]: '0x0B9427F175EfB95C3EbB7b9009B12dc685F517E6',
  [ChainId.MAINNET]: '0x79855A03426e15Ad120df77eFA623aF87bd54eF3'
}

export const INIT_CODE_HASH = {
  [ChainId.TESTNET]: '0x3b58864b0ea7cc084fc3a5dc3ca7ea2fb5cedd9aac7f9fff0c3dd9a15713f1c7',
  [ChainId.MAINNET]: '0x3b58864b0ea7cc084fc3a5dc3ca7ea2fb5cedd9aac7f9fff0c3dd9a15713f1c7'
}

export const ROUTER_ADDRESS = {
  [ChainId.TESTNET]: '0x59a4210Dd69FDdE1457905098fF03E0617A548C5',
  [ChainId.MAINNET]: '0x8c8067ed3bC19ACcE28C1953bfC18DC85A2127F7'
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const THIRTY = JSBI.BigInt(30)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _998 = JSBI.BigInt(998)
export const _1000 = JSBI.BigInt(1000)
export const _10000 = JSBI.BigInt(10000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}

export const PAIR_SWAP_FEE: { [key in ChainId]: { [key: string]: number } } = {
  [ChainId.TESTNET]: {},
  [ChainId.MAINNET]: {}
}
